.video-background {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    overflow: hidden;
    z-index: -1;
}

.video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    filter: grayscale() brightness(50%);
}
